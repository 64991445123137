@font-face {
    src: url('/src/assets/fonts/F37GingerBold.ttf') format('truetype');
    font-family: 'F37Ginger';
    font-weight: 700;
    font-display: swap;
}

@font-face {
    src: url('/src/assets/fonts/F37GingerRegular.ttf') format('truetype');
    font-family: 'F37Ginger';
    font-weight: 400;
    font-display: swap;
}

@font-face {
    src: url('/src/assets/fonts/F37GingerLight.ttf') format('truetype');
    font-family: 'F37Ginger';
    font-weight: 300;
    font-display: swap;
}

